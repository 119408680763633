import * as sort from './sort';

const addUnverifiedSujectsToAccounts = (profileData) => {
	if (profileData.profile?.unverified_emails?.length) {
		for (const email of profileData.profile.unverified_emails) {
			profileData.profile.accounts.push({
				id: `email::${email}`,
				user_name: email,
				display: email,
				slug: 'email',
				preferred: false,
				verifiedAt: false
			});
		}
	}

	if (profileData.profile?.unverified_phones?.length) {
		for (const phone of profileData.profile.unverified_phones) {
			profileData.profile.accounts.push({
				id: `phone::${phone}`,
				user_name: phone,
				display: phone,
				slug: 'phone',
				preferred: false,
				verifiedAt: false
			});
		}
	}
};

const updateAccounts = (profileData) => {
	//add sortrank, sortlabel and unverified accounts to accounts array
	addUnverifiedSujectsToAccounts(profileData);
	profileData.profile.accounts = sort.addSortRank(profileData.profile.accounts);
	profileData.profile.accounts = sort.addSortLabel(profileData.profile.accounts);

	return profileData;
};

export { addUnverifiedSujectsToAccounts, updateAccounts };
