<script>
	import { _, locale } from 'svelte-i18n';
	import { createEventDispatcher } from 'svelte';
	import { isRemoteAuthClient } from '$src/stores.js';
	import { slide } from 'svelte/transition';
	import Dropdown from '$lib/Dropdown.svelte';
	import MailIcon from '$lib/icon/MailIcon.svelte';
	import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte';
	import QRCodeIcon from '$lib/icon/QRCodeIcon.svelte';
	import VerifyEmail from '$lib/VerifyEmail.svelte';
	import LoginProvider from '$lib/LoginProvider.svelte';
	import LoginProviderGroup from '$lib/LoginProviderGroup.svelte';
	import ManagedProviderDropdown from '$lib/ManagedProviderDropdown.svelte';

	export let accountSelected = undefined;
	export let recommendedProviders = [];
	export let continueWithQRCodeAjax = false;
	export let showManagedLogin = false;
	export let showQRLogin = false;
	export let contactLoginSuccess = () => {};
	export let handleContactError = () => {};
	export let continueWithEthereumExtension = () => {};
	export let continueWithProvider = () => {};
	// export let continueWithWalletConnect = () => {};
	export let handleManagedEmailSuccess = () => {};

	const dispatch = createEventDispatcher();

	export let label = '';
	let managedDropdown = false;
	let emailDropdown = false;
	let showAllProviders = false;
</script>

{#if label}
	<h1 data-test="page-heading" class="relative font-medium -ml-2 mb-2">
		{label}
	</h1>
{/if}

<div data-test="above-fold-list" class="space-y-2 mb-2">
	{#each recommendedProviders as slug}
		{#if slug === 'email'}
			<Dropdown
				dataTest="email-btn"
				ariaLabel={$_('Continue with Email')}
				expanded={emailDropdown}
				id="email-container"
				on:click={() => (emailDropdown = !emailDropdown)}
			>
				<div class="h-12 w-full flex items-center justify-start px-4 gap-x-4">
					<MailIcon />
					<span class="block text-left" aria-hidden="true">
						{$_('Continue with Email')}
					</span>
				</div>
				{#if emailDropdown}
					<div class="pb-3 pt-1 px-4" transition:slide|local>
						<VerifyEmail
							login
							recommendedProvider={true}
							{accountSelected}
							on:success={contactLoginSuccess}
							on:error={handleContactError}
						/>
					</div>
				{/if}
			</Dropdown>
		{:else if slug === 'ethereum'}
			{#if window.ethereum?.isMetaMask}
				<LoginProvider
					login
					provider={{ display: 'MetaMask', slug: 'metamask' }}
					prefix="Continue with"
					on:click={() => continueWithEthereumExtension({ accountSelected })}
				/>
			{:else if window.ethereum}
				<LoginProvider
					login
					provider={{ display: 'Ethereum', slug: 'ethereum' }}
					prefix="Continue with"
					on:click={() => continueWithEthereumExtension({ accountSelected })}
				/>
			{/if}
			<!-- Note: Uncomment to show WalletConnect -->
			<!-- <LoginProvider
            login
            provider={{ slug: 'walletconnect', display: 'WalletConnect' }}
            prefix="Continue with"
            on:click={continueWithWalletConnect}
        /> -->
		{:else if !['qrcode', 'managed'].includes(slug)}
			<LoginProvider
				login
				provider={{ slug }}
				prefix="Continue with"
				on:click={(e) =>
					continueWithProvider({ slug, server: e.detail, body: { accountSelected } })}
				on:ethereum={() => continueWithEthereumExtension({ accountSelected })}
			/>
		{/if}
		{#if slug === 'qrcode' && showQRLogin && !$isRemoteAuthClient}
			<button
				class="group h-12 relative btn-background w-full flex items-center justify-start px-4"
				on:click={() => dispatch('qrcode')}
				disabled={continueWithQRCodeAjax}
			>
				{#if continueWithQRCodeAjax}
					<SpinnerIcon css="h-5.5 w-5.5 block mx-auto" />
				{:else}
					<QRCodeIcon css="h-4.5 opacity-80" />

					<span class="{$locale && $locale.startsWith('ar') ? 'mr-4' : 'ml-4'} truncate">
						{$_('Continue by scanning QR Code')}
					</span>

					<svg
						xmlns="http://www.w3.org/2000/svg"
						class="ml-auto stroke-2 group-hover:stroke-3 h-4.5 transform text-white dark:text-[#d4d4d4] opacity-80"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
					</svg>
				{/if}
			</button>
		{/if}
		{#if slug === 'managed' && showManagedLogin}
			<ManagedProviderDropdown
				login={true}
				hasBackground={true}
				{accountSelected}
				label={$_('Continue with Managed Provider')}
				bind:expanded={managedDropdown}
				on:managedEmailSuccess={handleManagedEmailSuccess}
			/>
		{/if}
	{/each}
</div>

<section data-test="below-fold-list" class="bg-[#DCDCDC] dark:bg-[#505050] -mx-2 rounded-md mt-2">
	<button
		data-test="show-below-fold-providers-btn"
		on:click={() => (showAllProviders = !showAllProviders)}
		class="w-full h-full px-6 group py-3 text-left flex items-center justify-between"
	>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			class="stroke-2 group-hover:stroke-3 group-focus:stroke-3 h-4.5 transform text-charcoal dark:text-[#d4d4d4] opacity-80 invisible"
			class:rotate-180={showAllProviders}
			fill="none"
			viewBox="0 0 24 24"
			stroke="currentColor"
		>
			<path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
		</svg>
		<span>{!showAllProviders ? 'Show other ways to log in' : 'Hide other ways to log in'}</span>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			class="stroke-2 group-hover:stroke-3 group-focus:stroke-3 h-4.5 transform text-charcoal dark:text-[#d4d4d4] opacity-80"
			class:rotate-180={showAllProviders}
			fill="none"
			viewBox="0 0 24 24"
			stroke="currentColor"
		>
			<path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
		</svg></button
	>

	{#if showAllProviders}
		<div class="px-2 pb-3 pt-1" transition:slide|local>
			<LoginProviderGroup
				login
				prefix="Continue with"
				{accountSelected}
				{continueWithQRCodeAjax}
				{showQRLogin}
				on:ethereum={() => continueWithEthereumExtension({ accountSelected })}
				on:qrcode={() => dispatch('qrcode')}
				ignoreProviders={recommendedProviders}
				cryptoWallets={!recommendedProviders.includes('ethereum')}
				on:managedEmailSuccess={handleManagedEmailSuccess}
				{showManagedLogin}
			>
				{#if !recommendedProviders.includes('email')}
					<Dropdown
						dataTest="email-btn"
						ariaLabel={$_('Continue with Email')}
						expanded={emailDropdown}
						id="email-container"
						on:click={() => (emailDropdown = !emailDropdown)}
					>
						<div class="h-12 w-full flex items-center justify-start px-4 gap-x-4">
							<MailIcon />
							<span class="block text-left" aria-hidden="true">
								{$_('Continue with Email')}
							</span>
						</div>
						{#if emailDropdown}
							<div class="pb-3 pt-1 px-4" transition:slide|local>
								<VerifyEmail
									login
									on:success={contactLoginSuccess}
									on:error={handleContactError}
									{accountSelected}
								/>
							</div>
						{/if}
					</Dropdown>
				{/if}
			</LoginProviderGroup>
		</div>
	{/if}
</section>
